import React from 'react';
import Layout from '../components/HomeLayout';
import './Carriers.css';
import CustomButton from "../components/Button";

function Index() {
    return <Layout>
        <div className="carriers-page">
            <header className="carrier-header">
                <div className="container-lg">
                    <div className="content">
                        <h1 className="common-SectionTitle">
                            Cheaply and Efficiently with the carrier of your choice
                        </h1>
                        <p className="common-MediumBodyText">
                            Reach your customers across the world and pan India with India’s leading carrier services.
                        </p>
                        <p className="common-ButtonGroup">
                            <button className="common-Button common-Button--dark">Ship with us</button>
                            <button className="common-Button">Contact Sales</button>
                        </p>
                    </div>
                </div>
            </header>
            <div id="waymore-section">
                <div className="container-lg section">
                    <section style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{flex: '1 0 25%',}} className="">
                            <div className="feature-icon">
                                <img src={require('../icons/carrier-page-icons/multi-carrier.svg')} alt="All Major Carriers"/>
                            </div>
                            <h2 className="common-BodyTitle">All Major Carriers</h2>
                        </div>
                        <div style={{flex: '1 0 25%',}} className="">
                            <div className="feature-icon">
                                <img src={require('../icons/carrier-page-icons/pincodes.svg')} alt="27000+ pincodes"/>
                            </div>
                            <h2 className="common-BodyTitle">27000+ pincodes</h2>
                        </div>
                        <div style={{flex: '1 0 25%',}}>
                            <div className="feature-icon">
                                <img src={require('../icons/carrier-page-icons/global_shipping.svg')} alt="220+ countries"/>
                            </div>
                            <h2 className="common-BodyTitle">220+ countries</h2>
                        </div>
                        <div style={{flex: '1 0 25%',}}>
                            <div className="feature-icon">
                                <img src={require('../icons/carrier-page-icons/low-cost.svg')} alt="Customer Experience"/>
                            </div>
                            <h2 className="common-BodyTitle">Cheaper rates</h2>
                        </div>


                    </section>
                </div>

            </div>
            <div className="carrier-partners">
                <div className="container-lg section section-intro">
                    <header>
                        <h1 className="common-SectionTitle">Our carrier partners</h1>
                        <h3 className="common-IntroText">With Shipmile, you can ship across the world in the same way
                            you ship domestically. No need of too many documents and processes, your intelligent and
                            intuitive Shipmile dashboard is all you need. Shipmile enables its clients reach their
                            customers across the world using the following carriers
                        </h3>
                    </header>
                    <div className="container-lg">
                        <div className="image-group-module--sectionGroup">
                            <div className="image-group-module--sectionItem">
                                <div className="image-group-module--sectionImage">
                                    <img src={require('../icons/fedex.png')} alt=""/>
                                </div>
                            </div>
                            <div className="image-group-module--sectionItem">
                                <div className="image-group-module--sectionImage">
                                    <img src={require('../icons/delhivery.jpg')} alt=""/>
                                </div>
                            </div>
                            <div className="image-group-module--sectionItem">
                                <div className="image-group-module--sectionImage">
                                    <img src={require('../icons/bluedart.png')} alt=""/>
                                </div>
                            </div>
                            <div className="image-group-module--sectionItem">
                                <div className="image-group-module--sectionImage">
                                    <img src={require('../icons/ecomexpress.png')} alt=""/>
                                </div>
                            </div>
                            <div className="image-group-module--sectionItem">
                                <div className="image-group-module--sectionImage">
                                    <img src={require('../icons/xpressbees.png')} alt=""/>
                                </div>
                            </div>
                            <div className="image-group-module--sectionItem">
                                <div className="image-group-module--sectionImage">
                                    <img src={require('../icons/shadowfax.png')} alt=""/>
                                </div>
                            </div><div className="image-group-module--sectionItem">
                            <div className="image-group-module--sectionImage">
                                <img src={require('../icons/dtdc_new.png')} alt=""/>
                            </div>
                        </div>
                            <div className="image-group-module--sectionItem">
                                <div className="image-group-module--sectionImage">
                                    <img src={require('../icons/gati-logo.png')} alt=""/>
                                </div>
                            </div>
                            <div className="image-group-module--sectionItem">
                                <div className="image-group-module--sectionImage">
                                    <img src={require('../icons/aramex.jpg')} alt=""/>
                                </div>
                            </div>
                            <div className="image-group-module--sectionItem">
                                <div className="image-group-module--sectionImage">
                                    <img src={require('../icons/DHL_rgb.png')} alt=""/>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
            <div id="testimonials">
                <div className="container-lg section section-intro">
                    <header>
                        <h1 className="common-SectionTitle">You are in good company.</h1>
                        <h3 className="common-IntroText">Join our other sellers who love shipmile
                        </h3>
                    </header>
                    <section className="testimonials-section">
                        <div className="testimonials-content">
                            <div className="testimonials-image">
                                <img src={require('../icons/bala-satish.png')} alt=""/>
                                <div className="common-BodyTitle">Bala Satish</div>
                                <div className="testimonials-contentCompany">Mydreamstore</div>
                            </div>
                            <div className="testimonials-contentSection">

                                <p className="testimonials-contentDescription quote__quote">
                                    Shipmile is the blackbox logistics solution we were looking for. It offers customers
                                    flexibility of shipping and an assurance of quality, which is hard to find and expensive
                                    to achieve otherwise.
                                </p>
                            </div>
                        </div>
                        <div className="testimonials-content">
                            <div className="testimonials-image">
                                <img src={require('../icons/sharath-rowdy.png')} alt=""/>
                                <div className="common-BodyTitle">Sharath</div>
                                <div className="testimonials-contentCompany">Rowdywear</div>
                            </div>
                            <div className="testimonials-contentSection">

                                <p className="testimonials-contentDescription quote__quote">
                                    Shipmile drastically reduced our order processing time from 3 days to 15 minutes.
                                </p>
                            </div>
                        </div>

                    </section>
                </div>

            </div>
            <div className="carriers-signup-section">
                <div className="container-lg section-intro">
                    <div className="common-BodyText">
                        Don't have shipmile account?
                    </div>
                    <p className="common-ButtonGroup">
                        <CustomButton type={"dark"}>Ship with us</CustomButton>
                        <CustomButton goto={"/contact-sales"}>Contact Sales</CustomButton>
                    </p>

                </div>
            </div>
        </div>
    </Layout>;
}


export default Index;
