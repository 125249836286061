import React from 'react';
import logo from '../primary.svg';
import { Link } from "react-router-dom";
import './Navigation.css';

function Index() {
    return <React.Fragment>
        <header className="globalNav">
            <div className="container-lg">
                <nav>
                    <ul className="navRoot">
                        <li className="navSection logo"><Link to="/" className={"rootLink"}><img src={logo} alt="Shipmile"/></Link></li>
                        <li className="navSection primary">
                            <Link to="/features" className="rootLink colorize">Features</Link>
                            <Link to="/global" className="rootLink colorize">International</Link>
                            <Link to="/carriers" className="rootLink colorize">Carriers</Link>
                            {/*<Link to="/integrations" className="rootLink colorize">Integrations</Link>*/}
                            <Link to="/about" className="rootLink colorize">About us</Link>
                            <Link to="/contact" className="rootLink colorize"><span>Contact</span></Link>
                        </li>
                    </ul>
                </nav>
                <div className="separator colorize"></div>
            </div>
        </header>

    </React.Fragment>;
}


export default Index;
