import React from 'react';
import './Footer.css';
import Subscribe from './Subscribe';

function Index() {
    return <footer className="globalFooter">
        <div className="globalFooterCTA globalFooterCTA--collect">
            <Subscribe/>
        </div>
        <div className="globalFooterNav">
            <div className="container-lg">
                <div className="siteNav">
                    <div className="column">
                        <h4>Features</h4>
                        <ul>
                            <li>International</li>
                        </ul>
                    </div>
                    <div className="column">
                        <h4>Policies</h4>
                        <ul>
                            <li>Careers</li>
                            <li>Privacy Policy</li>
                            <li>Data Policy</li>
                            <li>Claims policies</li>
                        </ul>
                    </div>
                </div>
                <div className="copyright">Copyright © 2019 Swift Analytic Solutions Private Limited. All Rights
                    Reserved.
                </div>
            </div>
        </div>
    </footer>;
}


export default Index;
