import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Index from './pages/home';
import Contact from './pages/contact';
import ContactSales from './pages/contact-sales';
import FeaturesPage from './pages/features';
import About from './pages/about';
import Carriers from './pages/carriers';
import International from './pages/international';
import {AppProvider} from '@shopify/polaris';

function App() {
    return (
        <AppProvider>
            <Router>
                <React.Fragment>
                    <Route path="/" exact component={Index}/>
                    <Route path="/contact-sales" component={ContactSales}/>
                    <Route path="/features" component={FeaturesPage}/>
                    <Route path="/contact/" component={Contact}/>
                    <Route path="/about/" component={About}/>
                    <Route path="/global/" component={International}/>
                    <Route path="/carriers/" component={Carriers}/>
                </React.Fragment>
            </Router>
        </AppProvider>
    );
}

export default App;
