import React from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import './HomeLayout.css';

function HomeLayout(props) {
    return <div
        style={{position: 'absolute', width: '100%', height: '100%', display: 'flex', 'flexDirection': 'column'}}>
        <Navigation/>
        <div style={{flexGrow: '1'}}>
            <main>
                {props.children}
            </main>
        </div>
        <Footer/>
    </div>;
}


export default HomeLayout;
