import React from 'react';
import Layout from '../components/HomeLayout';
import './About.css';

function Index() {
    return <Layout>
        <header className="about-header">
            <div className="container-lg">
                <div className="header-content">
                    <h1 className="common-PageTitle header-title">
                        Our mission is to enable <br/>global trade via Internet
                    </h1>
                    <p className="common-IntroText header-intro">
                        Shipmile is one of the leading logistics aggregators in India, enabling its clients to ship across the world with ease and efficiency. By providing widest choices of carriers like FedEx, Delhivery, Bluedart, Gati, Aramex, DotZot, XpressBees and more, shipmile helps its clients to reach their customers across 27,000+ pin codes across India easily at very low costs. The company is headquartered at Hyderabad, India and has regional sales office in Delhi, India                    </p>
                </div>
            </div>
        </header>
        <div id="leadership">
            <div className="container-lg section section-intro">
                <header>
                    <h1 className="common-SectionTitle">Leadership</h1>
                    <h3 className="common-IntroText">We are lead by a team who constantly questions, tinkers and challenges to unlock great creativity at every turn
                    </h3>
                </header>
                <section className="">
                    <div className="leadership-item">
                        <div className="leadership-title-section">
                            <div className="leadership-name-image">
                                <img src={require('../icons/karthik.jpg')} alt=""/>
                            </div>
                            <div className="leadership-name-section">
                                <h2 className="common-BodyTitle">Karthik Kolli</h2>
                                <div className="leadership-name-title">Founder and CEO</div>
                            </div>
                        </div>
                        <p className="common-BodyText">
                            Karthik founded Shipmile in 2015 and has been the CEO of the company since then. <br/><br/>He is an IIT Madras alumnus, with extensive experience in product development and software architecture. Before starting Shipmile, he worked with Morgan Stanley as Senior Engineer and at Meditab as Product Head. Karthik is a DOTA freak and loves to trek, watch movies and play chess.
                        </p>
                    </div>
                    <div className="leadership-item">
                        <div className="leadership-title-section">
                            <div className="leadership-name-image">
                                <img src={require('../icons/Sandeep.jpg')} alt=""/>
                            </div>
                            <div className="leadership-name-section">
                                <h2 className="common-BodyTitle">Sandeep Kuvvarapu</h2>
                                <div className="leadership-name-title">Director - Sales and Marketing</div>
                            </div>
                        </div>
                        <p className="common-BodyText">
                            Sandeep heads the Sales and Marketing team at Shipmile. <br/><br/>Before Shipmile, he worked with Ameyo as Product Marketing Head and with GGK Technologies as Marketing Head. He also had a brief stint as Associate Relationship Manager in Corporate Banking at ICICI Bank. Sandeep has completed his MBA from IIM Bangalore and also has a bachelor’s degree from IIT Madras. He also did a brief stint at UCD School of Business, Dublin. Sandeep loves photography and is a Movie buff.


                        </p>
                    </div>
                    <div  className="leadership-item">
                        <div className="leadership-title-section">
                            <div className="leadership-name-image">
                                <img src={require('../icons/Jeevan.jpeg')} alt=""/>
                            </div>
                            <div className="leadership-name-section">
                                <h2 className="common-BodyTitle">Jeevan Shashank</h2>
                                <div className="leadership-name-title">Chief Operating Officer</div>
                            </div>
                        </div>
                        <p className="common-BodyText">
                            Jeevan serves as Chief Operating Officer at Shipmile. <br/><br/>He completed both Bachelors and Masters from IIT Madras. Earlier to Shipmile, he worked with Toppr technologies as a consultant and at Bridge Solutions as a Software Engineer. Jeevan plays football on the weekends and Dota on the weekdays to keep himself relaxed.
                        </p>
                    </div>

                </section>
            </div>

        </div>
    </Layout>;
}


export default Index;
