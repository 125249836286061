import React from 'react';
import Layout from '../components/HomeLayout';
import {Link} from 'react-router-dom';
import './Home.css';


function Index({history}) {
    const gotoContactSales = () => {
        console.log(history);
        history.push('/contact-sales');
    };

    return <Layout>
        <header className="home-header">
            <div className="container-lg">
                <div className="content">
                    <h1 className="common-SectionTitle">
                        Intelligent Shipping Solution For your Business
                    </h1>
                    <p className="common-MediumBodyText">
                        Shipmile enables its clients in eliminating mundane work by automation processes and empowers
                        them to reach their customer with ease and efficiency. You can forget about paperwork, surprise
                        fees and confusing billing reconcillations.
                    </p>
                    <p className="common-ButtonGroup">
                        <button className="common-Button common-Button--dark">Start shipping</button>
                        <button className="common-Button" onClick={gotoContactSales}>Contact Sales</button>
                    </p>
                </div>
                <figure className="floating-cards">
                    &nbsp;
                </figure>
            </div>
        </header>
        <div id="waymore-section">
            <div className="container-lg section">
                <header>
                    <h1 className="common-SectionTitle">More than just a shipping solution</h1>
                </header>
                <section style={{display: 'flex', flexDirection: 'row'}}>
                    <div style={{flex: '1 0 33%', padding: '20px 40px 60px 0'}} className="">
                        <h2 className="common-BodyTitle">Automated shipping</h2>
                        <p className="common-BodyText">
                            With our machine learning expertise, we choose the most optimal courier partner for your
                            needs. Reducing delivery issues.
                        </p>
                    </div>
                    <div style={{flex: '1 0 33%', padding: '20px 40px 60px 0'}} className="">
                        <h2 className="common-BodyTitle">Drop shipping</h2>
                        <p className="common-BodyText">
                            Import your orders and schedule pickups at all at once. Shipmile makes drop shipping a
                            breeze.
                        </p>
                    </div>
                    <div style={{flex: '1 0 33%', padding: '20px 40px 60px 0'}}>
                        <h2 className="common-BodyTitle">Billing Reconcillation</h2>
                        <p className="common-BodyText">
                            With our machine learning expertise, we choose the most optimal courier partner for your
                            needs. Reducing delivery issues.
                        </p>
                    </div>

                </section>
                <section style={{display: 'flex', flexDirection: 'row'}} className="">

                    <div style={{flex: '1 0 33%', padding: '20px 40px 60px 0'}} className="">
                        <h2 className="common-BodyTitle">Automated NDR</h2>
                        <p className="common-BodyText">
                            Shipmile's Non-delivery reports automation cuts down customer calls and improves support
                            efficiency
                        </p>
                    </div>
                    <div style={{flex: '1 0 33%', padding: '20px 40px 60px 0'}}>
                        <h2 className="common-BodyTitle">Batching</h2>
                        <p className="common-BodyText">
                            Create all your labels at once and download it by product for faster packaging. Shipmile
                            empowers your operations by fitting into their workflow. </p>
                    </div>
                    <div style={{flex: '1 0 33%', padding: '20px 40px 60px 0'}} className="">
                        <h2 className="common-BodyTitle">White labelling</h2>
                        <p className="common-BodyText">
                            White label tracking with Shipmile. Show them your brand on every shipment they track. </p>
                    </div>
                </section>
            </div>

        </div>
        <div id="how-works">
            <div className="container-lg section section-intro">
                <header>
                    <h1 className="common-SectionTitle">How Shipmile Works</h1>
                    <h3 className="common-IntroText">Enabling our clients reach more than <b>27000</b>+ pincodes
                        in India and more than <Link className="common-Link" to="/international"> 220 countries</Link> across world
                    </h3>
                </header>
                <section className="how-section">
                    <div className="how-content">
                        <div className="how-logo how-signup">
                            <img src={require('../icons/signup.png')} alt=""/>
                        </div>
                        <div className="how-header">
                            <h2>Sign Up</h2>
                        </div>
                        <p className="common-BodyText">
                            Sign Up with Shipmile and get your own intuitive shipping dashboard
                        </p>
                    </div>
                    <div className="how-content">
                        <div className="how-logo how-signup">
                            <img src={require('../icons/integrate.jpg')} alt=""/>
                        </div>
                        <div className="how-header">
                            <h2>Integrate store</h2>
                        </div>
                        <p className="common-BodyText">
                            Integrate your website/ ecommerce portal with shipmile dashboard to fetch order list
                            directly into the dashboard

                        </p>
                    </div>
                    <div className="how-content">
                        <div className="how-logo how-signup">
                            <img src={require('../icons/start-shipping.jpg')} alt=""/>
                        </div>
                        <div className="how-header">
                            <h2>Start shipping</h2>
                        </div>
                        <p className="common-BodyText">
                            Load your wallet, generate Shipping labels and start shipping to your customers with your
                            favourite carrier at cheaper prices
                        </p>
                    </div>
                    <div className="how-content">
                        <div className="how-logo how-signup">
                            <img src={require('../icons/cod.png')} alt=""/>
                        </div>
                        <div className="how-header">
                            <h2>CoD Remittance</h2>
                        </div>
                        <p className="common-BodyText">
                            Receive COD remittance on weekly basis as per agreed upon terms and conditions

                        </p>
                    </div>

                </section>
            </div>

        </div>
        <div id="shipmile-experience">
            <div className="container-lg section section-intro">
                <header>
                    <h1 className="common-SectionTitle">The shipmile experience</h1>
                </header>
                <section className="experience-section">
                    <div className="experience-content">
                        <div className="how-logo how-signup">
                            <img src={require('../icons/grow-faster.jpg')} alt=""/>
                        </div>
                        <div className="experience-textContent">
                            <div className="common-BodyTitle">
                                <span>Grow Faster</span>
                            </div>
                            <p className="common-BodyText">
                                Shipmile infrastructure helps you assist your customer and resolve their queries
                                in no time. It also helps you to scale up fast without worrying about logistics
                            </p>
                        </div>
                    </div>
                    <div className="experience-content">
                        <div className="how-logo how-signup">
                            <img src={require('../icons/customer-experience.jpg')} alt=""/>
                        </div>
                        <div className="experience-textContent">
                            <div className="common-BodyTitle">
                                <span>Enhance Customer Experience</span>
                            </div>
                            <p className="common-BodyText">
                                Shipmile's Auto NDR engages your customer realtime to resolve issues. Saves time
                                for Operations and increases transparency for your customers </p>
                        </div>
                    </div>

                    <div className="experience-content">
                        <div className="how-logo how-signup">
                            <img src={require('../icons/increase-reach.jpg')} alt=""/>
                        </div>
                        <div className="experience-textContent">
                            <div className="common-BodyTitle">
                                <span>Increase reach</span>
                            </div>
                            <p className="common-BodyText">
                                Shipmile enables you to ship over 24000 pincodes in India and to over 140
                                countries. With shipmile you can ship your products twice as fast to your
                                customers </p>
                        </div>
                    </div>

                    <div className="experience-content">
                        <div className="how-logo how-signup">
                            <img src={require('../icons/reduce-returns.png')} alt=""/>
                        </div>
                        <div className="experience-textContent">
                            <div className="common-BodyTitle">
                                <span>Reduce Returns</span>
                            </div>
                            <p className="common-BodyText">
                                Shipmile's machine learning algorithms predict returns of Shipments ahead in
                                time and allocate most efficient shipping providers or advice you alternatives
                                for that order </p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div id="testimonials">
            <div className="container-lg section section-intro">
                <header>
                    <h1 className="common-SectionTitle">You are in good company.</h1>
                    <h3 className="common-IntroText">Join our other sellers who love shipmile
                    </h3>
                </header>
                <section className="testimonials-section">
                    <div className="testimonials-content">
                        <div className="testimonials-image">
                            <img src={require('../icons/bala-satish.png')} alt=""/>
                            <div className="common-BodyTitle">Bala Satish</div>
                            <div className="testimonials-contentCompany">Mydreamstore</div>
                        </div>
                        <div className="testimonials-contentSection">

                            <p className="testimonials-contentDescription quote__quote">
                                Shipmile is the blackbox logistics solution we were looking for. It offers customers
                                flexibility of shipping and an assurance of quality, which is hard to find and expensive
                                to achieve otherwise.
                            </p>
                        </div>
                    </div>
                    <div className="testimonials-content">
                        <div className="testimonials-image">
                            <img src={require('../icons/sharath-rowdy.png')} alt=""/>
                            <div className="common-BodyTitle">Sharath</div>
                            <div className="testimonials-contentCompany">Rowdywear</div>
                        </div>
                        <div className="testimonials-contentSection">

                            <p className="testimonials-contentDescription quote__quote">
                                Shipmile drastically reduced our order processing time from 3 days to 15 minutes.
                            </p>
                        </div>
                    </div>

                </section>
            </div>

        </div>
    </Layout>;
}


export default Index;
