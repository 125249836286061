import React from 'react';
import cx from 'classnames';
import Layout from '../components/HomeLayout';
import './Features.css';
import CustomButton from "../components/Button";

function Index({history}) {

    const features = [
        {
            title: 'Intuitive Dashboard',
            image: require('../icons/feature-images/dashboard.svg'),
            text: 'The shipmile dashboard is built such that our clients can easily navigate across and get the needed work done as soon as possible. All the needed information like finances, shipping details, non-delivery etc. are carefully categorized and placed in a way, so that no time is wasted searching for the required content. '
        },
        {
            title: 'Automated NDR',
            image: require('../icons/feature-images/ndr.svg'),
            text: 'Major time and manpower are wasted on the processing of the undelivered shipments and if immediate action is not taken, this might result in loss. Shipmile’s automated NDR system enables its clients to take action on the undelivered shipment in realtime, thereby reducing the return orders and saving time and money.'
        },
        {
            title: 'Multi-Warehouse PickUp',
            image: require('../icons/feature-images/multi-warehouse.svg'),
            text: 'Schedule pickups from multiple warehouses across India and our carrier partner will pick it up for delivery. This enables our clients to reach their customers as soon as possible by selecting the warehouse near to the client location. With multi-warehouse pickup, clients can also reduce the cost of shipping and deliver more efficiently.'
        },
        {
            title: 'Bulk Order Management',
            image: require('../icons/feature-images/bulk-upload.svg'),
            text: 'Shipment management and label creation has become a major hassle when ecommerce companies scale up. With Shipmile you can import your entire order list via platform integration or via .csv directly into the dashboard, where you can manage the orders with very few clicks.'
        },
        {
            title: 'End to End Tracking',
            image: require('../icons/feature-images/bulk-upload.svg'),
            text: 'Deliver greater customer experience to your customers by providing real-time tracking information like status of the order, estimated time of delivery etc. Shipmile enable its clients to track their shipments in real-time from creation of the shipment till the delivery. Clients also have a choice to opt to get tracking updates via mail and sms channels.'
        },
    ];


    return <Layout>
        <div className="features-page">
            <header className="features-header section">
                <div className="container-lg">
                    <div className="content">
                        <h1 className="common-SectionTitle">
                            Features which provide unparalleled shipping experience
                        </h1>
                        <p className="common-MediumBodyText">
                            Shipmile’s features are built in a way to provide a smooth and streamlined experience to its clients to reach their customers in the most efficient way.

                        </p>
                        <p className="common-ButtonGroup">
                            <CustomButton type={"dark"}>Ship with us</CustomButton>
                            <CustomButton goto={"/contact-sales"}>Contact Sales</CustomButton>
                        </p>
                    </div>
                    <figure className="floating-cards">
                        &nbsp;
                    </figure>
                </div>
            </header>
            <div id="shipmile-features">
                <div className="container-lg section section-intro">
                    <header>
                        <h1 className="common-SectionTitle">Features</h1>
                    </header>
                    <section className="shipmile-features-section">
                        {features.map((feature, idx) => {
                            return (<div className={cx("features-content", idx %2 === 1 ? "content-flip" : "")}>
                                <div className="features-content-image">
                                    <img className={idx %2 === 1 ? "img-padding-left" : "img-padding-right"} src={feature.image} alt=""/>
                                </div>
                                <div className="features-textContent">
                                    <div className="common-BodyTitle">
                                        <span>{idx + 1}. {feature.title}</span>
                                    </div>
                                    <p className="common-BodyText">
                                        {feature.text}
                                    </p>
                                </div>
                            </div>);
                        })}
                    </section>
                </div>
            </div>
            <div id="waymore-section">
                <div className="container-lg section">
                    <header>
                        <h1 className="common-SectionTitle">What makes Shipmile different</h1>
                    </header>
                    <section style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{flex: '1 0 25%', padding: '20px 40px 60px 0'}} className="">
                            <div className="feature-icon">
                                <img src={require('../icons/feature-icons/customer-experience.svg')} alt="Customer Experience"/>
                            </div>
                            <h2 className="common-BodyTitle">Greater End Customer Experience</h2>
                        </div>
                        <div style={{flex: '1 0 25%', padding: '20px 40px 60px 0'}} className="">
                            <div className="feature-icon">
                                <img src={require('../icons/feature-icons/pincodes.svg')} alt="Pincodes"/>
                            </div>
                            <h2 className="common-BodyTitle">27000+ Pincodes</h2>
                        </div>
                        <div style={{flex: '1 0 25%', padding: '20px 40px 60px 0'}}>
                            <div className="feature-icon">
                                <img src={require('../icons/feature-icons/global_shipping.svg')} alt="Global Shipping"/>
                            </div>
                            <h2 className="common-BodyTitle">Shipping Across Globe</h2>
                        </div>
                        <div style={{flex: '1 0 25%', padding: '20px 40px 60px 0'}} className="">
                            <div className="feature-icon">
                                <img src={require('../icons/feature-icons/multi-carrier.svg')} alt="Multiple Carrier Partners"/>
                            </div>
                            <h2 className="common-BodyTitle">Multiple Carrier Partners</h2>
                        </div>
                    </section>
                    <section style={{display: 'flex', flexDirection: 'row'}} className="">
                        <div style={{flex: '1 0 25%', padding: '20px 40px 60px 0'}}>
                            <div className="feature-icon">
                                <img src={require('../icons/feature-icons/zero-fee.svg')} alt="Zero Subscription Fee"/>
                            </div>
                            <h2 className="common-BodyTitle">Zero Subscription Fee</h2>
                        </div>
                        <div style={{flex: '1 0 25%', padding: '20px 40px 60px 0'}} className="">
                            <div className="feature-icon">
                                <img src={require('../icons/feature-icons/dashboard.svg')} alt="Intuitive & Intelligent Dashboard"/>
                            </div>
                            <h2 className="common-BodyTitle">Intuitive & Intelligent Dashboard</h2>
                        </div>
                        <div style={{flex: '1 0 25%', padding: '20px 40px 60px 0'}} className="">
                            <div className="feature-icon">
                                <img src={require('../icons/feature-icons/integrations.svg')} alt="Easy Integrations"/>
                            </div>
                            <h2 className="common-BodyTitle">Easy Integrations</h2>
                        </div>
                        <div style={{flex: '1 0 25%', padding: '20px 40px 60px 0'}} className="">
                            <div className="feature-icon">
                                <img src={require('../icons/feature-icons/low-cost.svg')} alt="Low-cost shipping"/>
                            </div>
                            <h2 className="common-BodyTitle">Low-Cost Shipping</h2>
                        </div>
                    </section>
                </div>
            </div>




            <div id="testimonials">
                <div className="container-lg section section-intro">
                    <header>
                        <h1 className="common-SectionTitle">You are in good company.</h1>
                        <h3 className="common-IntroText">Join our other sellers who love shipmile
                        </h3>
                    </header>
                    <section className="testimonials-section">
                        <div className="testimonials-content">
                            <div className="testimonials-image">
                                <img src={require('../icons/bala-satish.png')} alt=""/>
                                <div className="common-BodyTitle">Bala Satish</div>
                                <div className="testimonials-contentCompany">Mydreamstore</div>
                            </div>
                            <div className="testimonials-contentSection">

                                <p className="testimonials-contentDescription quote__quote">
                                    Shipmile is the blackbox logistics solution we were looking for. It offers customers
                                    flexibility of shipping and an assurance of quality, which is hard to find and expensive
                                    to achieve otherwise.
                                </p>
                            </div>
                        </div>
                        <div className="testimonials-content">
                            <div className="testimonials-image">
                                <img src={require('../icons/sharath-rowdy.png')} alt=""/>
                                <div className="common-BodyTitle">Sharath</div>
                                <div className="testimonials-contentCompany">Rowdywear</div>
                            </div>
                            <div className="testimonials-contentSection">

                                <p className="testimonials-contentDescription quote__quote">
                                    Shipmile drastically reduced our order processing time from 3 days to 15 minutes.
                                </p>
                            </div>
                        </div>

                    </section>
                </div>

            </div>
            <div className="international-signup-section">
                <div className="container-lg section-intro">
                    <div className="common-BodyText">
                        Don't have shipmile account?
                    </div>
                    <p className="common-ButtonGroup">
                        <CustomButton type={"dark"}>Sign up</CustomButton>
                        <CustomButton goto={"/contact-sales"}>Contact Sales</CustomButton>
                    </p>

                </div>
            </div>
        </div>
    </Layout>;
}


export default Index;
