import React from "react";
import cx from 'classnames';
import { withRouter } from "react-router-dom";
import './Button.css';

function Index(props) {

    const {history, type, goto, onClick} = props

    let btnClass = null;

    switch (type) {
        case 'dark':
            btnClass = "common-Button--dark";
            break;
        case 'default':
            btnClass = "common-Button--default";
            break;
        case 'disabled':
            btnClass = "common-Button--disabled";
            break
        default:
            btnClass = null
    }

    const handleOnClick = () => {
        onClick()
        if(goto) history.push(goto);
    }

    return (
        <button className={cx("common-Button", btnClass)} onClick={handleOnClick}>{props.children}</button>
    )
}

export default withRouter(Index);
