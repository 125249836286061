import React from 'react';
import Layout from '../components/HomeLayout';
import './Contact.css';

import ContactForm from '../components/ContactForm'

function Index() {

    return <Layout>
        <header className="contact-header">
            <div className="content">
                &nbsp;
            </div>
        </header>
        <div className="contact-address-section">
            <div className="container-lg section section-intro">
                <header>
                    <h1 className="common-SectionTitle">Contact Us</h1>
                    <h3 className="common-IntroText">Get in touch and let us know how we can help.</h3>
                </header>
                <section className="contact-address-row">
                    <div className="contact-address-item">
                        <h2 className="common-BodyTitle">Head Office (Hyderabad)</h2>
                        <p className="common-BodyText">
                            302, Green park residency, Road-4, Rajeev Nagar, Hyderabad,
                            Telangana - 500 081<br/>
                            +91 7731826094<br/>
                            <a href="mailto:sales@shipmile.com" className="common-Link ">sales@shipmile.com</a>

                        </p>
                        <div className="contact-footer">

                        </div>
                    </div>
                    <div className="contact-address-item">
                        <h2 className="common-BodyTitle">Regional Office (Delhi)</h2>
                        <p className="common-BodyText">
                            A 291, Block A, Shivalik
                            Malviya Nagar, New Delhi <br/>
                            Delhi - 110 017 <br/>
                            <a href="mailto:sales@shipmile.com" className="common-Link ">sales@shipmile.com</a>

                        </p>
                        <div className="contact-footer">

                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div className="contact-writeus-section" id="write-to-us">
            <div className="container-lg section">
                <header>
                    <h1 className="common-SectionTitle">Write to us</h1>
                </header>
                <div>
                    <ContactForm type={"demo"} />
                </div>
            </div>
        </div>
    </Layout>;
}


export default Index;
