import React from 'react';
import Layout from '../components/HomeLayout';
import './Contact.css';
import ContactForm from "../components/ContactForm";

function Index() {
   return <Layout>
        <div className="contact-header">
            <div className="contact-writeus-section" id="write-to-us">
                <div className="container-lg section">
                    <header>
                        <h1 className="common-SectionTitle">Contact sales</h1>
                    </header>
                    <div>
                        <ContactForm type={"interest"} />
                    </div>
                </div>
            </div>
        </div>
    </Layout>;
}


export default Index;
