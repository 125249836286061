import {Form, Input, Select} from "@rocketseat/unform";
import React, {useState} from "react";
import './ContactForm.css';
import * as Yup from "yup";

function Index(props) {

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formSubmitting, setIsFormSubmitting] = useState(false);


    const handleSubmit = (values) => {
        console.log(values);

        let data = values;

        data.type = props.type;

        setIsFormSubmitting(true);
        return fetch('/sales/new', {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        })
        .then(response => {
            return response.json()
        }).then(data => {
            if (data.success) {
                setFormSubmitted(true);
                setIsFormSubmitting(false);
            }
        })
    };

    const schema = Yup.object().shape({
        'First name': Yup.string().min(2).required(),
        'Last name': Yup.string().min(2).required(),
        'Email' : Yup.string().email("Invalid email").required('Invalid email'),
        'Company' : Yup.string().min(2).required(),
        'Job Title' : Yup.string().min(2).required(),
        'Phone' : Yup.string().min(10).required(),
    });

    return (
        <div className={"formSection"}>
            <Form id="validate-form" schema={schema} onSubmit={handleSubmit}>
                <div className={"formRow"}>
                    <div className={"formCol"}>
                        <label htmlFor="first_name">First Name</label>
                        <Input
                            className="formField"
                            name="First name"
                            id="first_name"
                        />
                    </div>
                    <div className={"formCol"}>
                        <label htmlFor="last_name">Last Name</label>
                        <Input
                            className="formField"
                            name="Last name"
                            id="last_name"
                        />
                    </div>
                </div>
                <div className={"formRow"}>
                    <div className={"formCol"}>
                        <label htmlFor="email">Work Email</label>
                        <Input
                            className="formField"
                            name="Email"
                            id="email"
                            type="email"
                        />
                    </div>
                </div>
                <div className={"formRow"}>
                    <div className={"formCol"}>
                        <label htmlFor="company">Company</label>
                        <Input
                            className="formField"
                            name="Company"
                            id="company"
                        />
                    </div>
                </div>
                <div className={"formRow"}>
                    <div className={"formCol"}>
                        <label htmlFor="select-job_title">Job title</label>
                        <Select
                            className="formField"
                            name="Job Title"
                            id="select-job_title"
                            options={["Director", "C-level", "Operations", "Sales", "Other"].map(x => {
                                return {
                                    id: x.toLowerCase(),
                                    title: x
                                }
                            })}
                        />
                    </div>
                </div>
                <div className={"formRow"}>
                    <div className={"formCol"}>
                        <label htmlFor="phone">Phone</label>
                        <Input
                            className="formField"
                            name="Phone"
                            id="phone"
                            type="number"
                        />
                    </div>
                </div>
                <div className={"formRow"}>
                    <div className={"formCol"}>
                        <label htmlFor="query">Tell us about your query:</label>
                        <Input multiline
                               className="formField"
                               name="Query"
                               id="query"
                        />
                    </div>
                </div>
                <div className={"formButtonRow"}>
                    {
                        formSubmitting ? <button className={"submitBtnSubmitting"}>
                                Submitting
                            </button> :
                            <span>
                                    {!formSubmitted ?
                                        <button className={"submitBtn"} type='submit'>
                                            Submit
                                        </button> : <button disabled={true} className={"thanksBtn"}>
                                            Will get back to you soon, Thank you
                                        </button>}
                                    </span>

                    }
                </div>
            </Form>

            <div className={"extraInfo"}>
                <p>
                    We will never sell your data to third parties
                </p>
            </div>
        </div>
    )
}

export default Index;
