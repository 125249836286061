import React, {useState} from 'react';

function Index() {
    const [email, setEmail] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        console.log(email);
    };

    return <React.Fragment>
        <div className="container-lg">
            <div className="content">
                <h1 className="title">
                    Get the latest from Shipmile
                </h1>
                <p className="common-MediumBodyText">
                    Receive news, announcements, and reports.
                </p>
            </div>
            <div className="common-ButtonGroup collect-email">
                <div className="collect-email-partial">
                    <form action="/api/subscribe-newsletter" method="POST" className="collect-form" onSubmit={onSubmit}>
                        <div className="collect-input email">
                            <input type="email" className="email-field" name="email"
                                   placeholder="kenny.sebastian@flipkart.com" value={email}
                                   onChange={e => setEmail(e.target.value)}/>
                            <button className="submit-button" type="submit">Subscribe</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </React.Fragment>;
}


export default Index;
