import React from 'react';
import Layout from '../components/HomeLayout';
import CustomButton from '../components/Button';
import './International.css';

function Index(props) {

    return <Layout>
        <div className="international-page">
            <header className="international-header">
                <div className="container-lg">
                    <div className="content">
                        <h1 className="common-SectionTitle">
                            Shipping International Made Easier and Cheaper
                        </h1>
                        <p className="common-MediumBodyText">
                            Start selling to potential consumers in 220+ countries without investing heavily into
                            logistics. Be it USA, Europe, Middle east, Australia, Africa or any where else in the world,
                            we make it easy for you to reach your customers. Leave all documentation and customs related
                            issues to us like they never existed.
                        </p>
                        <p className="common-ButtonGroup">
                            <CustomButton type={"dark"}>Ship with us</CustomButton>
                            <CustomButton goto={"/contact-sales"}>Contact Sales</CustomButton>
                        </p>
                    </div>
                    <figure className="floating-cards">
                        &nbsp;
                    </figure>
                </div>
            </header>
            <div id="waymore-section">
                <div className="container-lg section">
                    <header>
                        <h1 className="common-SectionTitle">What makes us so unique</h1>
                    </header>
                    <section style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{flex: '1 0 33%', padding: '20px 40px 60px 0'}} className="">
                            <div className="feature-icon">
                                <img src={require('../icons/international-icons/countries.svg')} alt="220+ countries"/>
                            </div>
                            <h2 className="common-BodyTitle">220+ countries</h2>
                        </div>
                        <div style={{flex: '1 0 33%', padding: '20px 40px 60px 0'}} className="">
                            <div className="feature-icon">
                                <img src={require('../icons/international-icons/renowed.svg')} alt="Renowed international carriers"/>
                            </div>
                            <h2 className="common-BodyTitle">Renowed international carriers</h2>
                        </div>
                        <div style={{flex: '1 0 33%', padding: '20px 40px 60px 0'}}>
                            <div className="feature-icon">
                                <img src={require('../icons/international-icons/no-min-order.svg')} alt="No minimum order commitment"/>
                            </div>
                            <h2 className="common-BodyTitle">No minimum order commitment</h2>
                        </div>

                    </section>
                    <section style={{display: 'flex', flexDirection: 'row'}} className="">

                        <div style={{flex: '1 0 33%', padding: '20px 40px 60px 0'}} className="">
                            <div className="feature-icon">
                                <img src={require('../icons/international-icons/tracking.svg')} alt="Complete tracking"/>
                            </div>
                            <h2 className="common-BodyTitle">Complete tracking</h2>
                        </div>
                        <div style={{flex: '1 0 33%', padding: '20px 40px 60px 0'}}>
                            <div className="feature-icon">
                                <img src={require('../icons/international-icons/documentation.svg')} alt="Minimum documentation"/>
                            </div>
                            <h2 className="common-BodyTitle">Minimum documentation</h2>
                        </div>
                        <div style={{flex: '1 0 33%', padding: '20px 40px 60px 0'}} className="">
                            <div className="feature-icon">
                                <img src={require('../icons/international-icons/no-hidden-charges.svg')} alt="No hidden charges"/>
                            </div>
                            <h2 className="common-BodyTitle">No hidden charges</h2>
                        </div>
                    </section>
                </div>

            </div>
            <div className="international-carrier-partners">
                <div className="container-lg section section-intro">
                    <header>
                        <h1 className="common-SectionTitle">Our carrier partners</h1>
                        <h3 className="common-IntroText">With Shipmile, you can ship across the world in the same way
                            you ship domestically. No need of too many documents and processes, your intelligent and
                            intuitive Shipmile dashboard is all you need. Shipmile enables its clients reach their
                            customers across the world using the following carriers
                        </h3>
                    </header>
                    <div className="container-lg">
                        <div className="image-group-module--sectionGroup">
                            <div className="image-group-module--sectionItem">
                                <div className="image-group-module--sectionImage">
                                    <img src={require('../icons/DHL_rgb.png')} alt=""/>
                                </div>
                            </div>
                            <div className="image-group-module--sectionItem">
                                <div className="image-group-module--sectionImage">
                                    <img src={require('../icons/aramex.jpg')} alt=""/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div id="how-works">
                <div className="container-lg section section-intro">
                    <header>
                        <h1 className="common-SectionTitle">How it Works</h1>

                    </header>
                    <section className="how-section">
                        <div className="how-content">
                            <div className="how-header">
                                <h2>Create shipment</h2>
                            </div>
                            <p className="common-BodyText">
                                Login to your dashboard and Create a Shipment
                            </p>
                        </div>
                        <div className="how-content">
                            <div className="how-header">
                                <h2>Pickup</h2>
                            </div>
                            <p className="common-BodyText">
                                Get your Shipment ready for pick up by our carrier partner

                            </p>
                        </div>
                        <div className="how-content">
                            <div className="how-header">
                                <h2>Delivery</h2>
                            </div>
                            <p className="common-BodyText">
                                Get the shipment delivered to your client. Happy Shipping
                            </p>
                        </div>
                    </section>
                </div>
            </div>
            <div id="testimonials">
                <div className="container-lg section section-intro">
                    <header>
                        <h1 className="common-SectionTitle">You are in good company.</h1>
                        <h3 className="common-IntroText">Join our other sellers who love shipmile
                        </h3>
                    </header>
                    <section className="testimonials-section">
                        <div className="testimonials-content">
                            <div className="testimonials-image">
                                <img src={require('../icons/bala-satish.png')} alt=""/>
                                <div className="common-BodyTitle">Bala Satish</div>
                                <div className="testimonials-contentCompany">Mydreamstore</div>
                            </div>
                            <div className="testimonials-contentSection">

                                <p className="testimonials-contentDescription quote__quote">
                                    Shipmile is the blackbox logistics solution we were looking for. It offers customers
                                    flexibility of shipping and an assurance of quality, which is hard to find and expensive
                                    to achieve otherwise.
                                </p>
                            </div>
                        </div>
                        <div className="testimonials-content">
                            <div className="testimonials-image">
                                <img src={require('../icons/sharath-rowdy.png')} alt=""/>
                                <div className="common-BodyTitle">Sharath</div>
                                <div className="testimonials-contentCompany">Rowdywear</div>
                            </div>
                            <div className="testimonials-contentSection">

                                <p className="testimonials-contentDescription quote__quote">
                                    Shipmile drastically reduced our order processing time from 3 days to 15 minutes.
                                </p>
                            </div>
                        </div>

                    </section>
                </div>

            </div>
            <div className="international-signup-section">
                <div className="container-lg section-intro">
                    <div className="common-BodyText">
                        Don't have shipmile account?
                    </div>
                    <p className="common-ButtonGroup">
                        <CustomButton type={"dark"}>Ship with us</CustomButton>
                        <CustomButton goto={"/contact-sales"}>Contact Sales</CustomButton>
                    </p>

                </div>
            </div>
        </div>
    </Layout>;
}


export default Index;
